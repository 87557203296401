import React from "react"
import styled from "styled-components"
import { ContentWrapper } from "../Layout"

export const BlogListWrapper = styled.section`
  margin-inline-start: auto;
  margin-inline-end: auto;
`

const BlogListLayout = ({ children }) => {
  return (
    <BlogListWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </BlogListWrapper>
  )
}

export default BlogListLayout
