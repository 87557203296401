import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import kebabCase from "lodash/kebabCase"
import styled from "styled-components"
import CategorySVG from "../CategorySVG"
import {
  neutral,
  navy,
  borderRadius,
  typeScale,
  perfectFourth,
  transition,
  boxShadow,
} from "../../utils"

const BlogPostCategories = () => {
  const data = useStaticQuery(GetCategories)
  return (
    <CategoryWrapper>
      {data.categories.group.map(category => (
        <Link to={`/blog/categories/${kebabCase(category.fieldValue)}/`}>
          <div key={category.nodes.id}>
            <CategorySVG category={category.fieldValue} />
            <CategoryTitle>{category.fieldValue}</CategoryTitle>
            <CategoryCount>{category.totalCount}</CategoryCount>
          </div>
        </Link>
      ))}
    </CategoryWrapper>
  )
}

export const CategoryWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  --webkit-overflow-scrolling: touch;
  padding-block-start: ${perfectFourth[600]};
  padding-block-end: ${perfectFourth[600]};
  a {
    width: 10rem;
    min-block-size: 7rem;
    flex-shrink: 0;
    padding-block-start: ${perfectFourth[400]};
    padding-block-end: ${perfectFourth[400]};
    padding-inline-start: 0;
    padding-inline-end: 0;
    background: ${neutral[100]};
    border-radius: ${borderRadius.reg};
    text-decoration: none;
    text-align: center;
    transition: all ${transition.reg};
    -webkit-box-shadow: ${boxShadow.reg};
    box-shadow: ${boxShadow.reg};
    &:hover {
      transform: scale(1.1);
      -webkit-box-shadow: ${boxShadow.hover};
      box-shadow: ${boxShadow.hover};
    }
  }
  > * + * {
    margin-inline-start: ${perfectFourth[500]};
  }
`

export const CategoryTitle = styled.p`
  font-weight: 900;
  color: ${navy[500]};
  font-size: ${typeScale.paragraph};
  text-transform: capitalize;
`

export const CategoryCount = styled.p`
  font-weight: 500;
  color: ${navy[500]};
  font-size: ${typeScale.paragraph};
`

export const GetCategories = graphql`
  {
    categories: allMdx {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
        nodes {
          id
        }
      }
    }
  }
`
export default BlogPostCategories
