import React from "react"
import Layout from "../components/Layout"
import HeroSecondary from "../components/HeroSecondary"
import { HeaderTwo } from "../components/Headers"
import { DotsFullWidth, InsideWrapper } from "../components/Layout"
import BlogListLayout from "../components/Blog/BlogListLayout"
import Posts from "../components/Posts"
import BlogPostCategories from "../components/Blog/BlogPostCategories"
import SEO from "../components/seo"
import usePosts from "../hooks/usePosts"

export default function Blog() {
  const { allposts } = usePosts()

  return (
    <>
      <SEO title="Blog" />
      <Layout>
        <HeroSecondary
          title="Learn a little CSS"
          subtitle="Bite sized CSS knoweldge for you to level up one small bit at a time."
        />
        <DotsFullWidth>
          <InsideWrapper>
            <div className="flow">
              <HeaderTwo>
                Explore by Category<span>.</span>
                <BlogPostCategories />
              </HeaderTwo>
            </div>
          </InsideWrapper>
        </DotsFullWidth>
        <BlogListLayout>
          <Posts posts={allposts.posts} title="View the latest posts" />
        </BlogListLayout>
      </Layout>
    </>
  )
}
