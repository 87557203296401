import { graphql, useStaticQuery } from "gatsby"

const usePosts = () => {
  const data = useStaticQuery(graphql`
    query {
      lastthree: allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        limit: 3
      ) {
        posts: nodes {
          id
          frontmatter {
            title
            author
            slug
            category
            date(formatString: "MMMM Do, YYYY")
          }
        }
      }
      allposts: allMdx(sort: { fields: frontmatter___date, order: DESC }) {
        posts: nodes {
          id
          frontmatter {
            title
            author
            slug
            category
            date(formatString: "MMMM Do, YYYY")
          }
        }
      }
    }
  `)

  return data
}

export default usePosts
