import React from "react"
import Fundamentals from "../assets/svg/fundamentals.svg"
import Layouts from "../assets/svg/layouts.svg"
import Animations from "../assets/svg/animations.svg"
import Forms from "../assets/svg/forms.svg"
import Text from "../assets/svg/text.svg"
import Projects from "../assets/svg/projects.svg"
import Bem from "../assets/svg/bem.svg"
import Advanced from "../assets/svg/advanced.svg"

const CategorySVG = ({ category }) => {
  return (
    <>
      {category === "fundamentals" && <Fundamentals />}
      {category === "layout" && <Layouts />}
      {category === "animation" && <Animations />}
      {category === "forms" && <Forms />}
      {category === "text" && <Text />}
      {category === "projects" && <Projects />}
      {category === "bem" && <Bem />}
      {category === "advanced" && <Advanced />}
    </>
  )
}

export default CategorySVG
